<template>
  <div class="debug">
    <h1>Debug</h1>
    <h2>Pages</h2>
    <ul>
      <li>
        <router-link :to="{name: 'Top', params: {lang: locale}}">1-1,2,3 トップページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'About', params: {lang: locale}}">3-1,2 英雄総選挙とは</router-link>
      </li>
      <li>
        <router-link :to="{name: 'SelectType', params: {lang: locale}}">4-1 投票方法選択</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Series', params: {lang: locale}}">5-1 シリーズ選択ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'HeroList', params: {lang: locale, seriesId: '101'}}">6-1 シリーズ英雄一覧ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Hero', params: {lang: locale, seriesId: '101', heroId: '101001'}}">7-1 英雄投票ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Voted', params: {lang: locale, seriesId: '101', heroId: '101001'}}">9-1 投票完了ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Random', params: {lang: locale}}">11-1 ランダム投票ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'MyPage', params: {lang: locale}}">12-1 マイページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Result', params: {lang: locale}}">14-1,2 結果発表ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'ResultList', params: {lang: locale, page: '1'}}">15-1 結果発表ページ詳細</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Character', params: {lang: locale}}">17-1 同一英雄ページ</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Error', params: {lang: locale}}">100 エラーページ（汎用）</router-link>
      </li>
      <li>
        <router-link :to="`/${locale}/hoge`">100 エラーページ（404）</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Error', params: {lang: locale}, query: {error: 'login'}}">100 エラーページ（ログインエラー）</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'

export default {
  name: 'Debug',
  mixins: [LocaleMixin, LoaderMixin],
  computed: {
    ...mapGetters('modal', ['modal'])
  }
}
</script>
