<template>
  <div class="Err">
    <div class="Err__MainTexts">
      <div class="Err__MainTextsInner">
        <template v-if="isNotFound">
          <p class="Err__Image"><img :src="require(`@images/err/txt_404.png`)" alt="404" /></p>
          <p class="Err__Text" v-html="$t('error.003')" />
        </template>
        <template v-if="isApiError">
          <p class="Err__Text" v-html="$t('error.001')" />
        </template>
        <template v-if="isLoginError">
          <p class="Err__Text" v-html="$t('error.004')" />
          <p class="Err__Link">
            <a :href="`/login?redirect=${redirectPath}`" v-html="$t('error.005')" />
          </p>
        </template>
      </div>
    </div>
    <button-a class="Err__ButtonTop" @click="$router.push({ name: 'Top', params: {lang: locale } })">
      <img :src="require(`@images/common/${locale}/txt_to-top.png`)" :alt="$t('error.006')" />
    </button-a>
    <button-official class="Err__ButtonOfficial" />
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import ButtonA from '@/components/global/button-a.vue'
import ButtonOfficial from '@/components/global/button-official.vue'

export default {
  name: 'Err',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    ButtonA,
    ButtonOfficial
  },
  computed: {
    isNotFound () {
      return this.$route.name === 'Error404'
    },
    isApiError () {
      return this.$route.name === 'Error' && !this.$route.query.error
    },
    isLoginError () {
      return this.$route.name === 'Error' && this.$route.query.error === 'login'
    },
    redirectPath () {
      const host = !process.env.VUE_APP_LOCAL_SERVER ? process.env.VUE_APP_URL : location.origin
      const path = `/${this.locale}`
      return `${host}${path}`
    }
  }
}
</script>

<style lang="scss" scoped>
.Err {
  @at-root {
    & {
      padding-bottom: 50px;
    }
    .Err__MainTexts {
      min-height: 300px;
      padding: 40px 20px;
      text-align: center;
      display: table;
      width: 100%;
    }
    .Err__MainTextsInner {
      display: table-cell;
      vertical-align: middle;
    }
    .Err__Text {
      margin-top: 35px;
      font-size: 3rem;
      text-shadow: 0 3px 8px rgba(0, 0, 0, .6);
      &:first-child {
        margin-top: 0;
      }
    }
    .Err__Link {
      margin-top: 35px;
      color: inherit;
      font-size: 3rem;
      text-decoration: underline;
      text-shadow: 0 3px 8px rgba(0, 0, 0, 0.6);
      @include tap-event();
    }
    .Err__ButtonTop {
      margin: 0 auto;
      min-width: 540px;
    }
    .Err__ButtonOfficial {
      margin: 40px auto 0;
    }
  }
}
</style>
